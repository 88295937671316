import { createApp, defineAsyncComponent } from 'vue';
import store from 'shared/store'
import { initFormKitSchema } from 'shared/utils/initFormKitSchema';
import RichTextCtaBanner from './RichTextCtaBanner.vue';
import './c-rich-text-cta-banner.scss';

const richTextCtaBanner = document.getElementById('rich-text-cta-banner');

if (richTextCtaBanner) {
	const app = createApp({
		name: 'RichTextCtaBannerApp',
	});
	app.component('rich-text-cta-banner', RichTextCtaBanner);
	app.component('send-campaign-id-cta-button', defineAsyncComponent(
		() => import('shared/components/actionButtonWithCTA/SendCampaignIdCtaButton.vue')
	));
	app.component('cta-button-with-popup-form', defineAsyncComponent(
		() => import('shared/components/actionButtonWithCTA/CtaButtonWithPopupForm.vue')
	));
	app.component('form-kit-builder-container', defineAsyncComponent(
		() => import('shared/components/FormKitBuilderContainer/FormKitBuilderContainer.vue')
	));
	initFormKitSchema(app);
	app.use(store);
	app.mount(richTextCtaBanner);
}